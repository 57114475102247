
.frames .owl-next{ right: 0;}
.frames .owl-prev{left: 0}
.frames .owl-theme .owl-nav [class*=owl-]{ height: 100%}
.frames .owl-prev, .frames .owl-next{height: 100% !important; width: 20px !important; margin: 0 !important; line-height: 110px; border-radius: 0 !important; color:#333 !important; background-color: #f5f5f5 !important;}
.frames .owl-carousel{padding: 0 25px;}

.frames .owl-theme .owl-nav [class*=owl-]:hover {
  background: #e2e2e2 !important;

}
